import React from "react";
import Sidebar from "../components/navigation/Sidebar";
import ApiCard from "../components/ui/ApiCard";
import routes from "../navigation/routes";
import ReactJson from "react-json-view";
import responseGetCollateral from "../assets/json/response-get-collateral";
import ToastHelper from "../helpers/toast-helper";
import PostmanImageSimple from "../assets/images/docs/2298e4.svg"
import AppLifecycleImage from "../assets/images/docs/6c452f.svg"
import AppLifecycleImageMobile from "../assets/images/docs/016102.svg"
import http from "../helpers/constants/http";
import Card from "react-bootstrap/Card";
import Header from "../components/navigation/Header";
import Translator from "../components/i18n/Translator";
import auth from "../assets/json/params/auth";
import getAllCollaterals from "../assets/json/params/get-all-collaterals";
import productItemId from "../assets/json/params/product_item_id";
import responseUpdateUserID from "../assets/json/params/response-update-user-ID";
import generateapikey from "../assets/json/params/generate-api-key";
import uploadimei from "../assets/json/params/uploadImei";
import sendMessage from "../assets/json/params/send-message";
import unlockOffline from "../assets/json/params/unlock-offline";

class Docs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardVisible: false
        };
    }

    handleDownload = () => {
        const collection = require('../assets/json/collection.json');
        const file = new Blob([JSON.stringify(collection, null, 2)], {type: 'application/json'});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'collection.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        if (file.size > 0) {
            ToastHelper.showSuccess('File downloaded successfully');
        } else {
            ToastHelper.showError('Failed to download file');
        }
    };


    render() {

        return <>
            <Header/>
            <Sidebar/>
            <main>
                <h1><Translator path={'docs.docs'}/></h1>
                <br/> <br/> <br/>

                <section id={routes.HOW_IT_WORKS}>
                    <h2><Translator path={'docs.how_it_works'}/></h2>
                    <br/>
                    <Card className="custom-card">
                        <Card.Body>
                            <Card.Title><Translator path={'docs.intro'}/></Card.Title>
                            <Translator path={'docs.purpose'}/>
                        </Card.Body>
                    </Card>
                    <br/>

                </section>

                <br/> <br/> <br/>

                <section className="api-section">
                    <h2><Translator path={'sidebar.getting_started'}/></h2>
                    <br/>

                    <div className="card-container">

                        {/*//First steps//*/}
                        <ApiCard
                            method={http.POST}
                            id={routes.API_KEY}
                            title="Auth"
                            text={
                            <div>
                                <p><Translator path={'docs.api_key'}/></p>
                            </div>}
                            params={<div>
                                <b><Translator path={'docs.bear_token'}/></b>
                            </div>}
                            columns={auth.columns}
                            rows={auth.rows}
                            endpoint="oauth2"
                            path={
                                <div>
                                    <b>oauth2</b>
                                    </div>
                            }

                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i
                                        className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                    <ReactJson src={generateapikey} name={false}/>
                                </div>
                            }
                        />

                                    {/*//Upload IMEI//*/}
                                    <ApiCard
                                        method={http.POST}
                                        id={routes.GET_COLLATERAL}
                                        title="Upload IMEI"
                                        text={<Translator path={'docs.imei_return'}/>}
                                        endpoint="upload-imei"
                                        path={<div><b>upload-imei</b></div>}
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                            <p><Translator path={'docs.ID'}/></p>
                                        </div>}
                                        response={
                                            <div style={{display: "grid"}}>
                                                <p className="response-code"><i
                                                    className="bi bi-check-circle-fill"></i> Status -
                                                    200 OK
                                                </p>
                                                <ReactJson src={uploadimei} name={false}/>
                                            </div>
                                        }
                                    />

                                    {/*//Get IMEI status//*/}
                                    <ApiCard
                                        method={http.GET}
                                        id={routes.DUE_DATE}
                                        title="Get IMEI status"
                                        text={<div>
                                            <p><Translator path={'docs.get_imei'}/></p>
                                                <b><Translator path={'docs.mandatory_parameter'}/></b>
                                        </div>}
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                        </div>}
                                        endpoint="imei-status?imei=DEVICE_IMEI"
                                        path={
                                            <div>
                                                <b>imei-status?imei=DEVICE_IMEI</b>
                                            </div>
                                        }
                                    />

                                    {/*//Get Collateral//*/}
                                    <ApiCard
                                        method={http.GET}
                                        id={routes.RELEASE}
                                        title="Get Collateral"
                                        text={<div>
                                            <p><Translator path={'docs.get_collateral'}/></p>
                                        </div>}
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                            <p><Translator path={'docs.ID'}/></p>
                                        </div>}
                                        endpoint="?imei=DEVICE_IMEI&id=COLLATERAL_ID"
                                        path={
                                            <div>
                                                <b>?imei=DEVICE_IMEI&id=COLLATERAL_ID</b>
                                            </div>
                                        }
                                    />

                                    {/*//Lock Collateral//*/}
                                    <ApiCard
                                        method={http.PUT}
                                        id={routes.GET_ALL}
                                        title="Lock Collateral"
                                        text={<div>
                                            <p><Translator path={'docs.get_all_collaterals'}/></p>
                                        </div>}
                                        columns={getAllCollaterals.columns}
                                        rows={getAllCollaterals.rows}
                                        endpoint="lock"
                                        path={
                                            <div>
                                                <b>lock</b>
                                            </div>
                                        }
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                            <p><Translator path={'docs.ID'}/></p>
                                        </div>}
                                        response={
                                            <div style={{display: "grid"}}>
                                                <p className="response-code"><i
                                                    className="bi bi-check-circle-fill"></i> Status -
                                                    200 OK
                                                </p>
                                                <ReactJson src={responseGetCollateral} name={false}/>
                                            </div>
                                        }
                                    />

                                    {/*//unlock//*/}
                                    <ApiCard
                                        method={http.PUT}
                                        id={routes.GET_BY_TAG}
                                        title="Unlock Collateral"
                                        text={<div>
                                            <p><Translator path={'docs.get_all_by_tag'}/></p>
                                        </div>}
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                            <p><Translator path={'docs.ID'}/></p>
                                        </div>}
                                        endpoint="unlock"
                                        path={
                                            <div>
                                                <b>unlock</b>
                                            </div>
                                        }
                                        response={
                                            <div style={{display: "grid"}}>
                                                <ReactJson src={responseGetCollateral} name={false}/>
                                            </div>
                                        }
                                    />

                        {/*//Offline Unlock//*/}
                        <ApiCard
                            method={http.POST}
                            id={routes.GET_OFFLINE_UNLOCK}
                            title="Offline Unlock"
                            params={<div>
                                <b><Translator path={'docs.get_collateral_params'}/></b>
                                <p><Translator path={'docs.params_unlock_IMEI'}/></p>
                                <p><Translator path={'docs.params_unlock_PASSKEY'}/></p>
                            </div>}
                            endpoint="generate-pin"
                            path={
                                <div>
                                    <b>generate-pin</b>
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <ReactJson src={unlockOffline} name={false}/>
                                </div>
                            }
                        />

                                    {/*//Release Collateral//*/}
                                    <ApiCard
                                        method={http.PUT}
                                        id={routes.RELEASE_COLLATERAL}
                                        title="Release Collateral"
                                        text={<div>
                                            <p><Translator path={'docs.update_product_item_id'}/></p>
                                        </div>}
                                        columns={productItemId.columns}
                                        rows={productItemId.rows}
                                        endpoint="release"
                                        path={
                                            <div>
                                                <b>release</b>

                                            </div>
                                        }
                                        params={<div>
                                            <b><Translator path={'docs.get_collateral_params'}/></b>
                                            <p><Translator path={'docs.IMEI'}/></p>
                                            <p><Translator path={'docs.ID'}/></p>
                                        </div>}
                                        response={
                                            <div style={{display: "grid"}}>
                                                <ReactJson src={responseGetCollateral} name={false}/>
                                            </div>
                                        }
                                    />


                        {/*//Update User ID//*/}
                        <ApiCard
                            method={http.PUT}
                            id={routes.UPDATE_PRODUCT_ITEM_ID}
                            title="Update User ID"
                            text={<div>
                                <p><Translator path={'docs.update_user_id'}/></p>
                            </div>}
                            columns={productItemId.columns}
                            rows={productItemId.rows}
                            endpoint="userid"
                            path={
                                <div>
                                    <b>userid</b>

                                </div>
                            }
                            params={<div>
                                <b><Translator path={'docs.get_collateral_params'}/></b>
                                <p><Translator path={'docs.IMEI'}/></p>
                                <p><Translator path={'docs.ID'}/></p>
                            </div>}
                            response={
                                <div style={{display: "grid"}}>
                                    <ReactJson src={responseUpdateUserID} name={false}/>
                                </div>
                            }
                        />

                        {/*//Send Message//*/}
                        <ApiCard
                            method={http.POST}
                            id={routes.SEND_MESSAGE}
                            title="Send Message"
                            text={<div>
                                <p><Translator path={'docs.send_message'}/></p>
                            </div>}
                            columns={productItemId.columns}
                            rows={productItemId.rows}
                            endpoint="send-message"
                            path={
                                <div>
                                    <b>send-message</b>

                                </div>
                            }
                            params={<div>
                                <b><Translator path={'docs.get_collateral_params'}/></b>
                                <p><Translator path={'docs.IMEI'}/></p>
                                <p><Translator path={'docs.ID'}/></p>
                                <p><Translator path={'docs.MESSAGE'}/></p>
                            </div>}
                            response={
                                <div style={{display: "grid"}}>
                                    <ReactJson src={sendMessage} name={false}/>
                                </div>
                            }
                        />

                        {/*//Start Blink Reminder//*/}
                        <ApiCard
                            method={http.POST}
                            id={routes.START_BLINK_REMINDER}
                            title="Start Blink Reminder"
                            columns={productItemId.columns}
                            rows={productItemId.rows}
                            endpoint="start-blink-reminder"
                            path={
                                <div>
                                    <b>start-blink-reminder</b>
                                </div>
                            }
                            params={<div>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.message_blink_reminder'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.imei_blink_reminder'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.tel'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.interval'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.timeLimitEnable'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.daysLimitEnable'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.daysLimit'}/></p>
                                <p style={{ paddingBottom: '10px' }}><Translator path={'docs.timeLimit'}/></p>
                            </div>}
                        />

                        {/*//Stop Blink Reminder//*/}
                        <ApiCard
                            method={http.POST}
                            id={routes.STOP_BLINK_REMINDER}
                            title="Stop Blink Reminder"
                            columns={productItemId.columns}
                            rows={productItemId.rows}
                            endpoint="stop-blink-reminder"
                            path={
                                <div>
                                    <b>stop-blink-reminder</b>
                                </div>
                            }
                            params={<div>
                                <p><Translator path={'docs.imei_blink_reminder'}/></p>
                            </div>}
                        />
                         </div>
                         </section>
                         </main>
        </>
    }
}

export default Docs;